<template>
    <div>
  
    <div class="iframe-container">
      <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="100%" height="100%" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FEaXvUe3394u2kcpQkjVTKd%2Fcase-study-ppt%3Fpage-id%3D1223%253A38022%26type%3Ddesign%26node-id%3D1223-38023%26viewport%3D411%252C5439%252C0.43%26scaling%3Dscale-down-width&hide-ui=1" allowfullscreen></iframe>
    </div>
  </div> 
  
  </template>
  
  <script>
  export default {
  
  };
  </script>
  <style lang="scss">
  
  .iframe-container {
    display:flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
  }
  </style>
  